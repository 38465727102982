<script>
import {
  authMethods,
  authFackMethods,
  notificationMethods,
} from "@/state/helpers";
import Layout from "../../layouts/auth";
import appConfig from "@/app.config";
import { mapState } from "vuex";

import { required, email, helpers } from "@vuelidate/validators";
import useVuelidate from "@vuelidate/core";

import { authComputed } from "@/state/helpers";

import { loadStripe } from '@stripe/stripe-js'
import firebase from 'firebase/compat/app';
import "firebase/firestore";

const stripeInit = loadStripe(process.env.VUE_APP_STRIPE_PERISHABLE_KEY)

/**
 * Register component
 */
export default {
  setup() {
    return { v$: useVuelidate() };
  },
  page: {
    title: "Register",
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  components: {
    Layout,
  },
  data() {
    return {
      registerButtonLoading: false,
      newUser: {
        name: '',
        email: '',
        password: '',
        plan: '',
        billing: '',
      },
      submitted: false,
      regError: null,
      tryingToRegister: false,
      isRegisterError: false,
      registerSuccess: false,
      paymentId: '',
      payment: {},
      payments: [],
      session: {},
      terms: [
        { text: 'Billed Yearly', value: 'yearly' },
        { text: 'Billed Monthly', value: 'monthly' }
      ],
      currency: 'usd',
      currencies: [
        { text: 'EUR', value: 'eur' },
        { text: 'USD', value: 'usd' }
      ],
      products: {
        basic: {
          monthly: {
            eur: '',
            usd: 'price_1NhUUyFWSspN01EY7fFo6UQj',
          },
          yearly: {
            eur: '',
            usd: 'price_1NbkNoFWSspN01EY8uWWosUG',
          },
        },
        premium: {
          monthly: {
            eur: '',
            usd: 'price_1NhUUSFWSspN01EYJZWr44Lr',
          },
          yearly: {
            eur: '',
            usd: 'price_1NbkODFWSspN01EYDHGRyaCK',
          },
        },
        pro: {
          monthly: {
            eur: '',
            usd: 'price_1NhUVWFWSspN01EYNz9Otpsf',
          },
          yearly: {
            eur: '',
            usd: 'price_1NbkOIFWSspN01EYSIRZEKxC',
          },
        },
      },
    };
  },
  validations: {
    newUser: {
      name: {
        required: helpers.withMessage("Please enter your name", required),
      },
      email: {
        required: helpers.withMessage("Please enter your email address", required),
        email: helpers.withMessage("Please enter a valid email address", email),
      },
      password: {
        required: helpers.withMessage("Please enter a password", required),
      },
      plan: {
        required: helpers.withMessage("Please select a plan", required),
      }
    },
  },
  created() {
    if (['basic', 'premium', 'pro'].includes(this.$route.query.plan)) {
      this.newUser.plan = this.capitalize(this.$route.query.plan);
    }
    else {
      this.newUser.plan = 'Pro';
    }
    if (['yearly', 'monthly'].includes(this.$route.query.billing)) {
      this.newUser.billing = this.capitalize(this.$route.query.billing);
    }
    else {
      this.newUser.billing = 'Yearly';
    }
  },
  computed: {
    ...mapState("authfack", ["status"]),
    ...authComputed,
    registrationData() {
      return {
        name: this.newUser.name,
        email: this.newUser.email,
        password: this.newUser.password,
        tier: this.newUser.plan.toLowerCase(),
        term: this.newUser.billing.toLowerCase(),
      }
    },
    notification() {
      return this.$store ? this.$store.state.notification : null;
    },
  },
  methods: {
    ...authMethods,
    ...authFackMethods,
    ...notificationMethods,
    // Try to register the user in with the email, name
    // and password they provided.
    
    capitalize(word) {
      const capitalizedFirst = word[0].toUpperCase();
      const rest = word.slice(1);
      return capitalizedFirst + rest;
    },

    tryToRegisterIn() {
      this.submitted = true;
      this.registerButtonLoading = true;
      // stop here if form is invalid
      this.v$.$touch();

      if (this.v$.$invalid) {
        return;
      } else {
        this.tryingToRegister = true;
        // Reset the regError if it existed.
        this.regError = null;
        return (
          this.register({
            name: this.registrationData.name,
            email: this.registrationData.email,
            password: this.registrationData.password,
            tier: this.registrationData.tier,
          })
            // eslint-disable-next-line no-unused-vars
            .then((token) => {
              this.tryingToRegister = false;
              this.isRegisterError = false;
              this.registerSuccess = true;
              if (this.registerSuccess) {
                setTimeout(() => {
                  this.createPayment(this.registrationData.tier, this.registrationData.term, this.currency);
                }, 3000);
              }
            })
            .catch((error) => {
              this.tryingToRegister = false;
              this.regError = error ? error : "";
              this.isRegisterError = true;
            })
        );
      }
    },

    // need to create Stripe customer in Firestore first before this will work
    async createPayment(tier, term, currency) {
      this.disableButtons = true
      this.paymentId = this.generateId()
      console.log(tier + term + currency)
      console.log('paymentId: ' + this.paymentId)
      console.log('uid: ' + this.currentUser.uid)
      await firebase.firestore()
        .collection('stripe_customers')
        .doc(this.currentUser.uid)
        .collection('payments')
        .doc(this.paymentId)
        .set({
          price: this.products[tier][term][currency],
          quantity: 1,
          tier: tier,
        })
        .catch(err => console.log(err))
      this.listenForSessionId()
    },

    generateId() {
      return 'p' + Math.floor(Math.random() * (99999999999 - 10000000000 + 1) + 10000000000).toString()
    },

    async listenForSessionId() {
      await firebase.firestore()
        .collection('stripe_customers')
        .doc(this.currentUser.uid)
        .collection('payments')
        .doc(this.paymentId)
        .onSnapshot((doc) => {
          this.payment = doc.data()
          console.log(doc.data())
        })
    },

    async redirectToCheckout() {
      await this.payment.id
      stripeInit
        .then(stripe => {
          stripe.redirectToCheckout({
            sessionId: this.payment.id,
          }).then(() => {
            console.log('Redirecting to Stripe')
          }).catch(err => {
            console.log(err)
          })
        })
    },
  },

  watch: {
    payment: function () {
      if (this.payment.id) {
        console.log('Checkout Session ID: ', this.payment.id)
        this.redirectToCheckout()
      }
    },
  },
};
</script>

<template>
  <Layout>
    <div class="row justify-content-center">
      <div class="col-md-8 col-lg-6 col-xl-5">
        <div class="card overflow-hidden">
          <div class="bg-soft bg-primary">
            <div class="row">
              <div class="col-7">
                <div class="text-primary p-4">
                  <!-- <div v-if="currentUser.uid">{{ currentUser.uid }}</div> -->
                  <h5 class="text-primary">Sign Up Now</h5>
                  <span v-if="currentUser">
                    {{ currentUser.uid }}
                  </span>
                  <p>Experience the power of data-driven insights.</p>
                </div>
              </div>
              <div class="col-5 align-self-end">
                <img
                  src="@/assets/images/profile-img.png"
                  alt
                  class="img-fluid"
                />
              </div>
            </div>
          </div>
          <div class="card-body pt-0">
            <div>
              <router-link to="/">
                <div class="avatar-md profile-user-wid mb-4">
                  <span class="avatar-title rounded-circle bg-light">
                    <img
                      src="@/assets/images/logo.svg"
                      alt
                      class="rounded-circle"
                      height="34"
                    />
                  </span>
                </div>
              </router-link>
            </div>

            <b-alert
              v-model="registerSuccess"
              class="mt-3"
              variant="success"
              dismissible
              >Registration successful. Redirecting...</b-alert
            >

            <b-alert
              v-model="isRegisterError"
              class="mt-3"
              variant="danger"
              dismissible
              >{{ regError }}</b-alert
            >

            <div
              v-if="notification.message"
              :class="'alert ' + notification.type"
            >
              {{ notification.message }}
            </div>

            <b-form class="p-2" @submit.prevent="tryToRegisterIn">
              <b-form-group
                class="mb-3"
                id="email-group"
                label="Name"
                label-for="name"
              >
                <b-form-input
                  id="name"
                  v-model="newUser.name"
                  type="text"
                  placeholder="Enter name"
                  :class="{
                    'is-invalid': submitted && v$.newUser.name.$error,
                  }"
                ></b-form-input>
                <div
                  v-if="submitted && v$.newUser.name.$error"
                  class="invalid-feedback"
                >
                  <span v-if="v$.newUser.name.required.$message">{{
                    v$.newUser.name.required.$message
                  }}</span>
                </div>
              </b-form-group>

              <b-form-group
                class="mb-3"
                id="fullname-group"
                label="Email"
                label-for="email"
              >
                <b-form-input
                  id="email"
                  v-model="newUser.email"
                  type="email"
                  placeholder="Enter email"
                  :class="{
                    'is-invalid': submitted && v$.newUser.email.$error,
                  }"
                ></b-form-input>
                <div
                  v-for="(item, index) in v$.newUser.email.$errors"
                  :key="index"
                  class="invalid-feedback"
                >
                  <span v-if="item.$message">{{ item.$message }}</span>
                </div>
              </b-form-group>

              <b-form-group
                class="mb-3"
                id="password-group"
                label="Password"
                label-for="password"
              >
                <b-form-input
                  id="password"
                  v-model="newUser.password"
                  type="password"
                  placeholder="Enter password"
                  :class="{
                    'is-invalid': submitted && v$.newUser.password.$error,
                  }"
                ></b-form-input>
                <div
                  v-if="submitted && v$.newUser.password.$error"
                  class="invalid-feedback"
                >
                  <span v-if="v$.newUser.password.required.$message">{{
                    v$.newUser.password.required.$message
                  }}</span>
                </div>
              </b-form-group>

              <b-form-group
                class="mb-3 form-label"
                id="plan-group"
                label="Plan"
                label-for="plan"
              >
                <b-form-select
                  class="form-select"
                  id="plan"
                  v-model="newUser.plan"
                  :options="['Basic', 'Premium', 'Pro']"
                  placeholder="Select your plan"
                  type="text"
                ></b-form-select>
                <div
                  v-if="submitted && v$.newUser.plan.$error"
                  class="invalid-feedback"
                >
                  <span v-if="v$.newUser.plan.required.$message">{{
                    v$.newUser.plan.required.$message
                  }}</span>
                </div>
              </b-form-group>

              <b-form-group
                class="mb-3 form-label"
                id="billing-group"
                label="Billing"
                label-for="billing"
              >
                <b-form-select
                  class="form-select"
                  id="billing"
                  v-model="newUser.billing"
                  :options="['Yearly', 'Monthly']"
                  placeholder="Select your billing period"
                  type="text"
                ></b-form-select>
                <div
                  v-if="submitted && v$.newUser.plan.$error"
                  class="invalid-feedback"
                >
                  <span v-if="v$.newUser.plan.required.$message">{{
                    v$.newUser.plan.required.$message
                  }}</span>
                </div>
              </b-form-group>

              <div class="mt-4 d-grid">
                <b-button
                  type="submit"
                  variant="primary"
                  class="btn-block"
                  :disabled="registerButtonLoading"
                >
                  <span v-if="registerButtonLoading"><b-spinner small></b-spinner></span>
                  <span v-else>Register</span>
                </b-button>
              </div>

              <!-- <div class="mt-4 text-center">
                <h5 class="font-size-14 mb-3">Sign up using</h5>

                <ul class="list-inline">
                  <li class="list-inline-item">
                    <a
                      href="javascript: void(0);"
                      class="
                        social-list-item
                        bg-primary
                        text-white
                        border-primary
                      "
                    >
                      <i class="mdi mdi-facebook"></i>
                    </a>
                  </li>
                  <li class="list-inline-item">
                    <a
                      href="javascript: void(0);"
                      class="social-list-item bg-info text-white border-info"
                    >
                      <i class="mdi mdi-twitter"></i>
                    </a>
                  </li>
                  <li class="list-inline-item">
                    <a
                      href="javascript: void(0);"
                      class="
                        social-list-item
                        bg-danger
                        text-white
                        border-danger
                      "
                    >
                      <i class="mdi mdi-google"></i>
                    </a>
                  </li>
                </ul>
              </div> -->

              <div class="mt-4 text-center">
                <p class="mb-0">
                  By registering you agree to the
                  <a href="javascript: void(0);" class="text-primary"
                    >Terms of Use</a
                  >
                </p>
              </div>
            </b-form>
          </div>
          <!-- end card-body -->
        </div>
        <!-- end card -->

        <div class="mt-5 text-center">
          <p>
            Already have an account?
            <router-link to="/login" class="fw-medium text-primary"
              >Login</router-link
            >
          </p>
          <p>
            © {{ new Date().getFullYear() }} Bulldata.ai
          </p>
        </div>
      </div>
      <!-- end col -->
    </div>
    <!-- end row -->
  </Layout>
</template>